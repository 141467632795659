'use client';

import BaseAppLayout from '@/components/base-app-layout';
import Chat from '@/components/chatbot/chat';
import Sessions from '@/components/chatbot/sessions';
import {useParams} from 'next/navigation';
import {useState} from 'react';

export default function Home() {
  const params = useParams();
  const sessionId = params['sessionId']?.toString();
  const [toolsOpen, setToolsOpen] = useState(false);

  return (
    <BaseAppLayout
      toolsHide={false}
      toolsOpen={toolsOpen}
      onToolsChange={({detail}) => setToolsOpen(detail.open)}
      tools={<Sessions toolsOpen={toolsOpen} />}
      toolsWidth={500}
      content={
        <div>
          <Chat sessionId={sessionId} />
        </div>

        // <ContentLayout
        //   header={
        //     <Header variant="h1" info={<Link variant="info">Info</Link>}>
        //       Page header
        //     </Header>
        //   }
        // >
        //   <Container
        //     header={
        //       <Header variant="h2" description="Container description">
        //         Container header
        //       </Header>
        //     }
        //   >
        //     <Chat sessionId={sessionId} />
        //   </Container>
        // </ContentLayout>
      }
    />
  );
}
