const prompts = [
  'I need to design a new microservice architecture. Can you help me diagram the key components and data flows?',
  "I'm working on a refactoring task. Can you review this code snippet and suggest improvements?",
  'I need to document the business rules for our new customer loyalty program. Can you help me write clear requirements?',
  "I'm having trouble understanding this complex algorithm. Can you explain it to me step-by-step?",
  'I need to generate some sample data for testing purposes. Can you create a JSON file with 100 realistic user records?',
  "I'm putting together a technical design document. Can you help me diagram the high-level system architecture?",
  'I need to write some integration tests for our API endpoints. Can you provide examples of how to structure the tests?',
  "I'm trying to optimize a database query that's running slowly. Can you analyze the query and suggest improvements?",
  'I need to create a sequence diagram to illustrate the flow of our user authentication process.',
  "I'm working on a new feature and need to understand the relevant business rules. Can you explain them to me?",
  "I'm having trouble understanding a particular coding pattern. Can you provide a simple example to illustrate it?",
  'I need to generate some realistic-looking fake data for our staging environment. Can you help me create a script to do that?',
];

// A function that will choose 3 different values from the `prompts` array at random.
// Ensure that no 2 prompts are the same.
export const getRandomPrompts = () => {
  const randomPrompts: string[] = [];
  while (randomPrompts.length < 3) {
    const randomPrompt = prompts[Math.floor(Math.random() * prompts.length)];
    if (!randomPrompts.includes(randomPrompt)) {
      randomPrompts.push(randomPrompt);
    }
  }
  return randomPrompts;
};
