import {
  Box,
  Button,
  Container,
  ExpandableSection,
  Popover,
  Spinner,
  StatusIndicator,
  TextContent,
} from '@cloudscape-design/components';
import {useState} from 'react';
import {JsonView, darkStyles} from 'react-json-view-lite';
import styles from '../../styles/chat.module.scss';
import {ChatBotConfiguration} from './types';

import Markdown from '@/components/markdown';
import {MessageType} from '@/models/message-type';
import {SessionMessage} from '@/models/session-message';
import * as userSessionRepo from '@/repositories/user-session-repo';
import 'react-json-view-lite/dist/index.css';
import '../../styles/app.scss';

export interface ChatMessageProps {
  message: SessionMessage;
  configuration?: ChatBotConfiguration;
  showMetadata?: boolean;
  isLoading: boolean;
  onRenderComplete: () => void;
}

export default function ChatMessage(props: ChatMessageProps) {
  const [loading] = useState<boolean>(false);
  const [userInitials] = useState(userSessionRepo.getUserInitials());
  const [userName] = useState(userSessionRepo.getUserDetails()?.name);

  return (
    <div>
      {props.message?.type === MessageType.AI && (
        <TextContent>
          <div className="margin-b5">
            <span className="logo"></span>{' '}
            <span className="city-chat-label margin-l10 bold">City Chat</span>
          </div>
          <Container
            //header={<div><span class="city-chat">AI</span><span>City Chat</span></div>}
            className="chat-text"
            footer={
              ((props.showMetadata && props.message.metadata) ||
                (props.message.metadata &&
                  props.configuration?.showMetadata)) && (
                <ExpandableSection variant="footer" headerText="Metadata">
                  <JsonView
                    data={props.message.metadata}
                    style={{
                      ...darkStyles,
                      stringValue: 'jsonStrings',
                      numberValue: 'jsonNumbers',
                      booleanValue: 'jsonBool',
                      nullValue: 'jsonNull',
                      container: 'jsonContainer',
                    }}
                  />
                </ExpandableSection>
              )
            }
          >
            {!props.message.text?.length ? (
              <Box>
                <Spinner />
              </Box>
            ) : null}
            {(props.message.text?.length ?? 0) > 0 ? (
              <div className={styles.btn_chabot_message_copy}>
                <Popover
                  size="medium"
                  position="top"
                  triggerType="custom"
                  dismissButton={false}
                  content={
                    <StatusIndicator type="success">
                      Copied to clipboard.
                    </StatusIndicator>
                  }
                >
                  <Button
                    variant="inline-icon"
                    iconName="copy"
                    onClick={() => {
                      navigator.clipboard.writeText(props.message.text);
                    }}
                  />
                </Popover>
              </div>
            ) : null}
            <Markdown
              text={props.message.text}
              isLoading={props.isLoading}
              onRenderComplete={props.onRenderComplete}
            />
          </Container>
        </TextContent>
      )}
      {loading && (
        <Box float="left">
          <Spinner />
        </Box>
      )}
      {props.message?.type === MessageType.Human && (
        <TextContent>
          <div>
            <span className="human-chat">{userInitials}</span>
            <span className="bold">{userName}</span>
          </div>
          <span className="human-msg">{props.message.text}</span>
          {/* <div><span class="city-chat">AI</span><span>City Chat</span></div> */}
        </TextContent>
      )}
    </div>
  );
}
