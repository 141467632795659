import {ModelInfo} from '@/models/model-info';
import {SessionInfo} from '@/models/session-info';
import {SessionMessage} from '@/models/session-message';
import * as modelRepo from '@/repositories/model-repo';
import {
  Button,
  Container,
  FileInput,
  SelectProps,
  SpaceBetween,
  Spinner,
  NonCancelableCustomEvent,
  FileInputProps,
} from '@cloudscape-design/components';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import 'regenerator-runtime';
import {OptionsHelper} from '../../common/helpers/options-helper';
import {StorageHelper} from '../../common/helpers/storage-helper';
import {AppContext} from '../../models/app-context';
import styles from '../../styles/chat.module.scss';
import {
  ChatBotConfiguration,
  ChatInputState,
  ChatMessagePayload,
  FileStorageProvider,
} from './types';
import {getSelectedModelMetadata} from './utils';
import {v4 as uuidv4} from 'uuid';
import * as chatSessionRepo from '@/repositories/chat-session-repo';
import * as userSessionRepo from '@/repositories/user-session-repo';

export interface ChatInputPanelProps {
  running: boolean;
  setRunning: Dispatch<SetStateAction<boolean>>;
  session: SessionInfo | undefined;
  messageHistory: SessionMessage[];
  setMessageHistory: Dispatch<SetStateAction<SessionMessage[]>>;
  configuration: ChatBotConfiguration;
  setConfiguration: Dispatch<React.SetStateAction<ChatBotConfiguration>>;
  isWaitingForAI: boolean;
  onMessageSent: (message: ChatMessagePayload) => void;
}

export abstract class ChatScrollState {
  static userHasScrolled = false;
  static skipNextScrollEvent = false;
  static skipNextHistoryUpdate = false;
}

export default function ChatInputPanel(props: ChatInputPanelProps) {
  const appContext = useContext(AppContext);
  const [fileInput, setFileInput] = useState<File | null>(null);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);

  const [state, setState] = useState<ChatInputState>({
    value: '',
    selectedModel: null,
    selectedModelMetadata: null,
    modelsStatus: 'loading',
    file: null,
  });

  const handleSendMessage = async () => {
    const message: ChatMessagePayload = {
      text: state.value,
      file: state.file,
    };

    // Reset state and pass the message to the parent component
    setState(state => ({...state, value: '', file: null}));
    setFileInput(null);
    setSelectedFileName(null);
    props.onMessageSent(message);
  };

  // useEffect(() => {
  //   if (transcript) {
  //     setState(state => ({ ...state, value: transcript }));
  //   }
  // }, [transcript]);

  useEffect(() => {
    (async () => {
      const models = modelRepo.getModels();
      const selectedModelOption = getSelectedModelOption(models);
      const selectedModelMetadata = getSelectedModelMetadata(
        models,
        selectedModelOption
      );

      setState(state => ({
        ...state,
        models: modelRepo.getModels(),
        selectedModel: selectedModelOption,
        selectedModelMetadata,
        modelsStatus: models.length > 0 ? 'finished' : 'error',
      }));
    })();
  }, [appContext, state.modelsStatus]);

  useEffect(() => {
    const onWindowScroll = () => {
      if (ChatScrollState.skipNextScrollEvent) {
        ChatScrollState.skipNextScrollEvent = false;
        return;
      }

      const distanceFromEnd = Math.abs(
        window.innerHeight +
          window.scrollY -
          document.documentElement.scrollHeight
      );

      if (distanceFromEnd > 10) {
        ChatScrollState.userHasScrolled = true;
      } else {
        ChatScrollState.userHasScrolled = false;
      }
    };

    window.addEventListener('scroll', onWindowScroll);

    return () => {
      window.removeEventListener('scroll', onWindowScroll);
    };
  }, []);

  useLayoutEffect(() => {
    if (ChatScrollState.skipNextHistoryUpdate) {
      ChatScrollState.skipNextHistoryUpdate = false;
      return;
    }

    if (!ChatScrollState.userHasScrolled && props.messageHistory.length > 0) {
      ChatScrollState.skipNextScrollEvent = true;
      window.scrollTo({
        top: document.documentElement.scrollHeight + 1000,
        behavior: 'instant',
      });
    }
  }, [props.messageHistory]);

  const handleFileChange = async (
    event: NonCancelableCustomEvent<FileInputProps.ChangeDetail>
  ) => {
    const selectedFile = event.detail.value[0];

    if (selectedFile) {
      const promptId = uuidv4();

      try {
        // Call createChatFileLink to get the signed URL
        const {getUrl, putUrl, key} = await chatSessionRepo.createChatFileLink(
          props.session?.id || 'UNKNOWN_SESSION_ID',
          promptId,
          userSessionRepo.getUsername() || 'UNKNOWN_USER',
          selectedFile.name
        );

        // Upload the file to S3
        await fetch(putUrl, {
          method: 'PUT',
          body: selectedFile,
        });

        // Update the state with ImageFile metadata
        setState(state => ({
          ...state,
          file: {
            provider: FileStorageProvider.S3,
            key,
            url: getUrl,
          },
        }));

        setFileInput(selectedFile);
        setSelectedFileName(selectedFile.name);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  return (
    <SpaceBetween direction="vertical" size="l">
      <Container disableContentPaddings={true} disableHeaderPaddings={true}>
        <div className={styles.input_and_file_preview}>
          <div className={styles.input_textarea_container}>
            <div className={styles.file_upload_button_wrapper}>
              <FileInput
                variant="icon"
                accept=".txt,.csv,.ts,.tsx"
                multiple={false}
                onChange={handleFileChange}
                value={fileInput ? [fileInput] : []}
              />
            </div>

            <TextareaAutosize
              style={{marginBottom: '10px', padding: '10px'}}
              className={styles.input_textarea}
              maxRows={6}
              minRows={1}
              cols={85}
              spellCheck={true}
              autoFocus
              onChange={e =>
                setState(state => ({...state, value: e.target.value}))
              }
              onKeyDown={e => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  if (!props.isWaitingForAI) {
                    handleSendMessage();
                  }
                }
              }}
              value={state.value}
              placeholder="Send a message."
            />
            <div className={styles.send_button_container}>
              <Button
                onClick={handleSendMessage}
                className="ces-red"
                variant="primary"
                // Removing this for ux reasons (clashes w/ file upload icon)
                // iconName="upload"
                disabled={props.isWaitingForAI}
              >
                {props.running ? (
                  <>
                    Loading
                    <Spinner />
                  </>
                ) : (
                  'Send'
                )}
              </Button>
            </div>
          </div>
        </div>

        {selectedFileName && (
          <div className={styles.file_preview}>
            Selected File: {selectedFileName}
          </div>
        )}
      </Container>
      {/* <div className={styles.input_controls}>
        <div className={styles.input_controls_selects_1}>
          <Select
            disabled={props.running}
            statusType={state.modelsStatus}
            loadingText="Loading models (might take few seconds)..."
            placeholder="Select a model"
            empty={
              <div>
                No models available. Please make sure you have access to Amazon
                Bedrock or alternatively deploy a self hosted model on SageMaker
                or add API_KEY to Secrets Manager
              </div>
            }
            filteringType="auto"
            selectedOption={state.selectedModel}
            onChange={({detail}) => {
              setState(state => ({
                ...state,
                selectedModel: detail.selectedOption,
                selectedModelMetadata: getSelectedModelMetadata(
                  state.models,
                  detail.selectedOption
                ),
              }));
              if (detail.selectedOption?.value) {
                StorageHelper.setSelectedLLM(detail.selectedOption.value);
              }
            }}
            options={modelsOptions}
          />
        </div>
        <div className={styles.input_controls_right}>
          <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
            <div style={{paddingTop: '1px'}}>
              {props.session && (
                <ConfigDialog
                  sessionId={props.session.id}
                  visible={configDialogVisible}
                  setVisible={setConfigDialogVisible}
                  configuration={props.configuration}
                  setConfiguration={props.setConfiguration}
                />
              )}
              <Button
                disabled={true}
                iconName="settings"
                variant="icon"
                onClick={() => setConfigDialogVisible(true)}
              />
            </div>
            <StatusIndicator type={'in-progress'}>In Progress</StatusIndicator>
          </SpaceBetween>
        </div>
      </div> */}
    </SpaceBetween>
  );
}

function getSelectedModelOption(
  models: ModelInfo[]
): SelectProps.Option | null {
  let selectedModelOption: SelectProps.Option | null = null;
  const savedModel = StorageHelper.getSelectedLLM();

  if (savedModel) {
    const targetModel = models.find(m => m.id === savedModel);

    if (targetModel) {
      selectedModelOption = OptionsHelper.getSelectOptionGroups([
        targetModel,
      ])[0].options[0];
    }
  }

  return selectedModelOption;
}
