import {ModelInfo} from '@/models/model-info';

const newModelInfo = (id: string, shortDescription: string): ModelInfo => {
  return {id, shortDescription};
};

// The MVP will only allow 1 model.

export const getModels = (): ModelInfo[] => {
  return [newModelInfo('ClaudeV2', 'Anthropic Claude v2')];
};
