import {LoadingStatus} from '../../common/types';
import {SelectProps} from '@cloudscape-design/components';
import {ModelInterface} from '../../common/types';
import {ModelInfo} from '@/models/model-info';
import {MessageType} from '@/models/message-type';
import {MessageFeedbackScore} from '@/models/message-feedback-score';

export interface ChatBotConfiguration {
  streaming: boolean;
  showMetadata: boolean;
  maxTokens: number;
  temperature: number;
  topP: number;
  files: ImageFile[] | null;
}

export interface ChatInputState {
  value: string;
  selectedModel: SelectProps.Option | null;
  selectedModelMetadata: ModelInfo | null;
  modelsStatus: LoadingStatus;
  file: ImageFile | null;
}

export interface ChatMessageFeedbackState {
  value: string;
  score: MessageFeedbackScore;
}

export enum ChatBotAction {
  Heartbeat = 'heartbeat',
  Run = 'run',
  FinalResponse = 'final_response',
  LLMNewToken = 'llm_new_token',
  Error = 'error',
}

export enum ChatBotModelInterface {
  Langchain = 'langchain',
  Idefics = 'idefics',
}

export enum ChatBotMode {
  Chain = 'chain',
}

export enum FileStorageProvider {
  S3 = 's3',
}

export interface ImageFile {
  provider: FileStorageProvider;
  key: string;
  url: string;
}

export interface ChatBotHeartbeatRequest {
  action: ChatBotAction.Heartbeat;
  modelInterface: ModelInterface;
}

export interface ChatBotRunRequest {
  action: ChatBotAction.Run;
  modelInterface: ModelInterface;
  data: {
    modelName: string;
    provider: string;
    sessionId?: string;
    text: string;
    mode: string;
    modelKwargs?: Record<string, string | boolean | number>;
  };
}

export interface ChatBotToken {
  sequenceNumber: number;
  runId?: string;
  value: string;
}

export interface ChatBotHistoryItem {
  type: MessageType;
  content: string;
  metadata: Record<
    string,
    string | boolean | number | null | undefined | ImageFile[]
  >;
  tokens?: ChatBotToken[];
}

export interface ChatBotMessageResponse {
  action: ChatBotAction;
  data: {
    sessionId: string;
    token?: ChatBotToken;
    content?: string;
    metadata: Record<
      string,
      string | boolean | number | null | undefined | ImageFile[]
    >;
  };
}

export enum ChabotInputModality {
  Text = 'TEXT',
  Image = 'IMAGE',
}

export enum ChabotOutputModality {
  Text = 'TEXT',
  Image = 'IMAGE',
  Embedding = 'EMBEDDING',
}

export interface ChatMessagePayload {
  text: string;
  file: ImageFile | null;
}

export interface DraftMessage {
  text?: string;
  file?: ImageFile | null;
}
