import {SelectProps} from '@cloudscape-design/components';
import {ModelInfo} from '@/models/model-info';

export async function getSignedUrl() {
  // const signedUrl = await Storage.get(key as string);
  const signedUrl = '';
  return signedUrl;
}

export function getSelectedModelMetadata(
  models: ModelInfo[] | undefined,
  selectedModelOption: SelectProps.Option | null
): ModelInfo | null {
  let selectedModelMetadata: ModelInfo | null = null;

  if (selectedModelOption) {
    const id = selectedModelOption.value!;
    const targetModel = models?.find(m => m.id === id);

    if (targetModel) {
      selectedModelMetadata = targetModel;
    }
  }

  return selectedModelMetadata;
}
