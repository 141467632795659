import {MessageType} from '@/models/message-type';
import {SessionDetail} from '@/models/session-detail';
import {SessionMessage} from '@/models/session-message';
import * as chatSessionRepo from '@/repositories/chat-session-repo';
import {
  Button,
  Container,
  Header,
  SpaceBetween,
  StatusIndicator,
} from '@cloudscape-design/components';
import * as luxon from 'luxon';
import Image from 'next/image';
import {useEffect, useState, useCallback, useRef} from 'react';
import styles from '../../styles/chat.module.scss';
import ChatInputPanel, {ChatScrollState} from './chat-input-panel';
import ChatMessage from './chat-message';
import {ChatBotConfiguration, ChatMessagePayload} from './types';
import {getRandomPrompts} from '@/repositories/prompt-repo';
import {v4 as uuidv4} from 'uuid';

export default function Chat(props: {sessionId: string}) {
  const [running, setRunning] = useState<boolean>(false);
  const [session, setSession] = useState<SessionDetail>();
  const [configuration, setConfiguration] = useState<ChatBotConfiguration>(
    () => ({
      streaming: true,
      showMetadata: false,
      maxTokens: 512,
      temperature: 0.6,
      topP: 0.9,
      files: null,
    })
  );
  const [isWaitingForAI, setIsWaitingForAI] = useState<boolean>(false);
  const [pendingMessage, setPendingMessage] = useState<SessionMessage | null>(
    null
  );
  const [messageHistory, setMessageHistory] = useState<SessionMessage[]>([]);
  const subscriptionRef = useRef<ZenObservable.Subscription | null>(null);

  const cleanupSubscription = useCallback(() => {
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
      subscriptionRef.current = null;
    }
  }, []);

  useEffect(() => {
    return () => {
      cleanupSubscription();
    };
  }, [cleanupSubscription]);

  useEffect(() => {
    cleanupSubscription();

    if (!session?.id) return;

    const onNext = (nxt: chatSessionRepo.NotifyPromptResponse) => {
      try {
        const output = nxt.data.notifyPromptResponse.output ?? '';
        const isComplete = nxt.data.notifyPromptResponse.complete;

        setPendingMessage(prev => {
          const newMessage = {
            datestamp: luxon.DateTime.now(),
            type: MessageType.AI,
            text: prev ? prev.text + output : output,
            metadata: {
              isComplete,
              id: prev?.metadata.id ?? `Z${uuidv4()}`,
            },
          };

          if (isComplete) {
            setMessageHistory(history => {
              const updatedHistory = history.filter(
                msg => msg.metadata.id !== newMessage.metadata.id
              );
              return [...updatedHistory, newMessage];
            });
            return null;
          }

          return newMessage;
        });

        setIsWaitingForAI(!isComplete);
      } catch (error) {
        console.error('Error processing message:', error);
        setIsWaitingForAI(false);
      }
    };

    const subscription = chatSessionRepo.subscribeToChat(
      session.id,
      onNext,
      error => {
        console.error('Subscription error:', error);
        setIsWaitingForAI(false);
      },
      () => {
        console.log('Subscription closed');
        setIsWaitingForAI(false);
      }
    );

    subscriptionRef.current = subscription;
  }, [session?.id]);

  const scrollToEnd = useCallback(() => {
    requestAnimationFrame(() => {
      const elem = document.getElementById('chat_end');
      if (!elem) return;

      window.scrollBy({
        top: elem.getBoundingClientRect().y - window.innerHeight * 0.9,
        behavior: 'smooth',
      });
    });
  }, []);

  useEffect(() => {
    if (!isWaitingForAI) {
      scrollToEnd();
    }
  }, [messageHistory, isWaitingForAI, scrollToEnd]);

  const reloadSession = async () => {
    try {
      const sessionId = session?.id ?? props.sessionId;
      console.log('Reloading session:', sessionId);
      let gotSession: SessionDetail | undefined = undefined;

      try {
        gotSession = await chatSessionRepo.getSession(sessionId!);
        console.log('Got session:', gotSession);
      } catch (e) {
        console.log('Creating new session.');
        const summary = await chatSessionRepo.createSession(
          'Untitled Conversation'
        );
        gotSession = await chatSessionRepo.newSessionDetail(summary);
        console.log('Created session:', gotSession);
      }

      if (!gotSession) {
        throw new Error('Unable to generate chat session.');
      }

      setSession(gotSession);

      if (gotSession.messages) {
        const filteredMessageHistory = gotSession.messages.filter(
          message => message.text && message.text.trim() !== ''
        );
        ChatScrollState.skipNextHistoryUpdate = true;
        ChatScrollState.skipNextScrollEvent = true;
        setMessageHistory(filteredMessageHistory ?? []);
      }

      setRunning(false);
      scrollToEnd();
    } catch (error) {
      console.error('Error reloading session:', error);
      setRunning(false);
    }
  };

  useEffect(() => {
    setMessageHistory([]);
    setPendingMessage(null);

    if (!props.sessionId) {
      chatSessionRepo.newSessionDetail().then(x => setSession(x));
      return;
    }

    reloadSession();
  }, [props.sessionId]);

  const handleMessageSent = async (message: ChatMessagePayload) => {
    try {
      setIsWaitingForAI(true);
      setPendingMessage(null);

      if (message.file) {
        console.log('File metadata received:', message.file);
      }

      const newChatRecord = await chatSessionRepo.submitPrompt(
        session?.id ?? 'UNKNOWN_SESSION_ID',
        message.text,
        message.file ?? undefined
      );

      setMessageHistory(history => [
        ...history,
        {
          datestamp: luxon.DateTime.now(),
          type: MessageType.Human,
          text: message.text,
          metadata: {
            isComplete: true,
            id: newChatRecord.recordId,
            files: message.file ? [message.file] : [],
          },
        },
      ]);
    } catch (error) {
      console.error('Error sending message:', error);
      setIsWaitingForAI(false);
    }
  };

  const onRenameSession = () => {
    if (!session?.id) return;

    const newSessionName = window.prompt('Enter a new session name:');
    if (newSessionName) {
      chatSessionRepo.renameSession(session?.id, newSessionName);
      setSession(s => (s ? {...s, name: newSessionName} : s));
    }
  };

  const choosePrompt = async (prompt: string) => {
    await handleMessageSent({text: prompt, file: null});
  };

  return (
    <div>
      <div style={{position: 'fixed', marginTop: '-90px', zIndex: '2'}}>
        <Container>
          <Header
            variant="h1"
            info={
              <Button variant="link" onClick={onRenameSession}>
                Rename
              </Button>
            }
          >
            {session?.name ?? 'Loading...'}
          </Header>
        </Container>
      </div>
      <div className={styles.chat_container} style={{marginTop: '90px'}}>
        <SpaceBetween direction="vertical" size="m">
          {messageHistory.map(message => (
            <ChatMessage
              key={message.metadata.id}
              message={message}
              configuration={configuration}
              isLoading={false}
              onRenderComplete={scrollToEnd}
            />
          ))}
          {isWaitingForAI && (
            <>
              {pendingMessage && (
                <ChatMessage
                  key={`pending-${pendingMessage.metadata.id}`}
                  message={pendingMessage}
                  configuration={configuration}
                  isLoading={true}
                  onRenderComplete={scrollToEnd}
                />
              )}
              <StatusIndicator type="in-progress">Thinking...</StatusIndicator>
            </>
          )}
          <div id="chat_end" />
          <div id="chat_footer" style={{height: '260px'}} />
        </SpaceBetween>
        <div className={styles.welcome_text}>
          {messageHistory.length === 0 && session && (
            <>
              <div>
                <center>
                  <Image src="/AI.svg" alt="AI" width="82" height="82" />
                </center>
              </div>
              <div>
                <center>How can I help you today?</center>
                {getRandomPrompts().map(prompt => (
                  <Button
                    key={prompt}
                    variant="link"
                    onClick={() => choosePrompt(prompt)}
                  >
                    {prompt}
                  </Button>
                ))}
              </div>
            </>
          )}
          {!session && (
            <center>
              <StatusIndicator type="loading">Loading session</StatusIndicator>
            </center>
          )}
        </div>
        <div id="input_container" className={styles.input_container}>
          <ChatInputPanel
            session={session}
            running={running}
            setRunning={setRunning}
            messageHistory={messageHistory}
            setMessageHistory={setMessageHistory}
            configuration={configuration}
            setConfiguration={setConfiguration}
            onMessageSent={handleMessageSent}
            isWaitingForAI={isWaitingForAI}
          />
        </div>
      </div>
    </div>
  );
}
